import React, { useRef, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
  useMediaQuery,
  Collapse,
  IconButton,
  Button,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";

interface PricingCardProps {
  title: string;
  interiorDetails: string[];
  exteriorDetails: string[];
  duration: string;
  price: string;
}
interface PricingCardProps {
  title: string;
  interiorDetails: string[];
  exteriorDetails: string[];
  duration: string;
  price: string;
  image: string;
  contactLink: string;
}

const PricingCard: React.FC<PricingCardProps> = ({
  title,
  interiorDetails,
  exteriorDetails,
  duration,
  price,
  image,
  contactLink,
}) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const [expanded, setExpanded] = useState(false);

  const navigateTo = useNavigate();

  return (
    <Card
      sx={{
        height: "100%",
        "&:hover": { boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)" },
        cursor: "pointer",
        backgroundColor: "rgb(30, 30, 30)",
        color: "white",
      }}
      onClick={() => {
        if (isMobile) {
          setExpanded((prev) => !prev);
        } else {
          navigateTo(contactLink);
        }
      }}
    >
      <CardContent>
        <Typography fontSize="25px" align="center" gutterBottom mt={1}>
          {title.toUpperCase()}
        </Typography>

        {isMobile && (
          <img
            src={image}
            style={{
              width: "calc(100% + 32px)",
              objectFit: "cover",
              marginLeft: "-16px",
              aspectRatio: "4.5/3",
            }}
            alt="detailing-thumbnail"
          />
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="body2">
              Average Duration: {duration}
            </Typography>
            <Typography variant="body2">Price: {price}</Typography>
          </div>
          {isMobile && (
            <IconButton aria-label="Expand more" title="Expand more">
              <ExpandMoreIcon
                style={{
                  transform: expanded ? "rotate(180deg)" : "none",
                  color: "white",
                }}
              />
            </IconButton>
          )}
        </div>
        {isMobile && (
          <React.Fragment>
            <Collapse in={expanded} sx={{ marginTop: "10px" }}>
              <Typography variant="body1" textAlign={"left"} fontWeight={"600"}>
                Exterior Details:
              </Typography>
              <List>
                {exteriorDetails.map((detail, index) => (
                  <ListItem key={index} sx={{}}>
                    <CheckIcon
                      style={{ color: "#d6bf7b", marginRight: "7px" }}
                    />
                    <ListItemText primary={detail} />
                  </ListItem>
                ))}
              </List>
              <Typography variant="body1" textAlign={"left"} fontWeight={"600"}>
                Interior Details:
              </Typography>
              <List>
                {interiorDetails.map((detail, index) => (
                  <ListItem key={index} sx={{}}>
                    <CheckIcon
                      style={{ color: "#d6bf7b", marginRight: "7px" }}
                    />
                    <ListItemText primary={detail} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </React.Fragment>
        )}

        {!isMobile && (
          <div>
            <Typography variant="body1" textAlign={"left"} fontWeight={"600"}>
              Exterior Details:
            </Typography>
            <List>
              {exteriorDetails.map((detail, index) => (
                <ListItem key={index} sx={{}}>
                  <CheckIcon style={{ color: "#d6bf7b", marginRight: "7px" }} />
                  <ListItemText primary={detail} />
                </ListItem>
              ))}
            </List>
            <Typography variant="body1" textAlign={"left"} fontWeight={"600"}>
              Interior Details:
            </Typography>
            <List>
              {interiorDetails.map((detail, index) => (
                <ListItem key={index} sx={{}}>
                  <CheckIcon style={{ color: "#d6bf7b", marginRight: "7px" }} />
                  <ListItemText primary={detail} />
                </ListItem>
              ))}
            </List>
          </div>
        )}
        {isMobile && (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              mt: 2,
              backgroundColor: "#262626",
              "&:hover": {
                backgroundColor: "#141414",
              },
            }}
            onClick={(e) => {
              e.stopPropagation();
              navigateTo(contactLink);
            }}
          >
            Get in contact
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

const PricingComponent: React.FC = () => {
  const pricingData = [
    {
      title: "MAINTENANCE DETAIL",
      exteriorDetails: [
        "Foamed Cannon Pre-wash",
        "Foamed Hand Wash",
        "Wheels, Arcehes & Tyres Decontamination",
        "Dried with Microfiber Towel",
        "Hand-Applied Tyre Dressing",
      ],
      interiorDetails: [
        "Interior Wiped Down",
        "Complete Interior Vacuumed",
        "Door Frame and Boot Jams Cleaned",
        "Windows Interior & Exterior Cleaned",
        "Interior Air Refreshed",
      ],
      duration: "1 - 2hrs",
      price: "$",
      image: "/images/services/img1.jpeg",
      contactLink: "/contact?service=maintenance",
    },

    {
      title: "MINI DETAIL",
      exteriorDetails: [
        "Foamed Cannon Pre-wash",
        "Foamed Hand Wash",
        "Wheels, Arcehes & Tyres Decontamination",
        "Dried with Blower & Microfiber Towel",
        "Hand-Applied Tyre Dressing",
        "Spray Sealant that Improves Paint Gloss and Provides Protection",
      ],
      interiorDetails: [
        "Interior Scrubbed Down",
        "Floor Mats are Shampooed then Extracted",
        "Complete Interior Vacuumed",
        "Door Frame and Boot Jams Cleaned",
        "Windows Interior & Exterior Cleaned",
        "Interior Air Refreshed",
      ],
      duration: "2 - 3hrs",
      price: "$$",
      image: "/images/services/img2.jpeg",
      contactLink: "/contact?service=mini",
    },
    {
      title: "ULTIMATE DETAIL",
      exteriorDetails: [
        "Foamed Cannon Pre-wash",
        "Foamed Hand Wash",
        "Wheels, Arcehes & Tyres Decontamination",
        "Dried with Blower & Microfiber Towel",
        "Hand-Applied Tyre Dressing",
        "Iron Fallout Removal",
        "Authentic Premium Carnauba Wax",
      ],
      interiorDetails: [
        "Seats, Carpet, Floor Mats are Steamed Cleaned & Shampooed then Extracted",
        "Door Trims, Dashboard & Centre Console, Steam Cleaned & Scrubbed",
        "Complete Interior Vacuumed",
        "Door Frame and Boot Jams Cleaned",
        "Windows Interior & Exterior Cleaned",
        "Interior Air Refreshed",
      ],
      duration: "4 - 5hrs",
      price: "$$$",
      image: "/images/services/img3.jpeg",
      contactLink: "/contact?service=ultimate",
    },
    {
      title: "SHOWROOM DETAIL",
      exteriorDetails: [
        "Foamed Cannon Pre-wash",
        "Foamed Hand Wash",
        "Wheels, Arcehes & Tyres Decontamination",
        "Dried with Blower & Microfiber Towel",
        "Hand-Applied Tyre Dressing",
        "Stage 1 & 2 Paint Correction",
        "4 Year Hydro Ceramic Coating or Ceramic Coating",
      ],
      interiorDetails: [
        "Interior Wiped Down",
        "Complete Interior Vacuumed",
        "Door Frame and Boot Jams Cleaned",
        "Windows Interior & Exterior Cleaned",
        "Interior Air Refreshed",
      ],
      duration: "2 - 3 days",
      price: "$$$$",
      image: "/images/services/img4.jpeg",
      contactLink: "/contact?service=showroom",
    },
  ];

  const servicesRef = useRef<HTMLDivElement | null>(null);

  return (
    <div id="services" ref={servicesRef} style={{ marginTop: "60px" }}>
      <Box>
        <Typography variant="h4" mt="15px" fontWeight={"500"} align="center">
          Services
        </Typography>

        <Grid container spacing={2} justifyContent="center" padding={4}>
          {pricingData.map((data, index) => (
            <Grid key={index} item xs={12} sm={12} md={6} lg={3}>
              <PricingCard {...data} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
};

export default PricingComponent;
